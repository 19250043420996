import { configureStore } from '@reduxjs/toolkit';
import { RTKmiddleware } from './RTKmiddlewares';

// => SLICE reduces
import authReducer from '../features/auth/authSlice';
import systemConfigReducer from '../features/systemConfig/systemConfigSlice';
import gallariesReducer from '../features/gallaries/gallariesSlice';
import eventTypesReducer from '../features/eventTypes/eventTypesSlice';
import clientsReducer from '../features/clients/clientsSlice';
import partinersReducer from '../features/partiners/partinersSlice';
import eventsReducer from "../features/events/eventsSlice";

// => RTK reducers
import { authAPI } from '../../api/authAPI';
import { eventTypeAPI } from '../../api/eventTypeAPI/eventTypeAPI';
import { partinerAPI } from '../../api/partinerAPI/partinerAPI';
import { clientAPI } from '../../api/clientAPI/clientAPI';
import { gallaryAPI } from '../../api/gallaryAPI/gallaryAPI';
import { eventAPI } from '../../api/eventAPI/eventAPI';
import { contactUsAPI } from '../../api/contactUs/contactUsAPI';

export const store = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [clientAPI.reducerPath]: clientAPI.reducer,
    [eventTypeAPI.reducerPath]: eventTypeAPI.reducer,
    [partinerAPI.reducerPath]: partinerAPI.reducer,
    [gallaryAPI.reducerPath]: gallaryAPI.reducer,
    [eventAPI.reducerPath]: eventAPI.reducer,
    [contactUsAPI.reducerPath]: contactUsAPI.reducer,
    authReducer,
    systemConfigReducer,
    gallariesReducer,
    eventTypesReducer,
    clientsReducer,
    partinersReducer,
    eventsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(RTKmiddleware),
});
