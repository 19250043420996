import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../baseUrl/baseURL';
import { contactUsEndpoints } from './contactUsEndpoints';

export const contactUsAPI = createApi({
    reducerPath: 'contactUsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        contactUs: builder.mutation({
            query: (payload) => ({
                url: `${contactUsEndpoints.CONTACT_US}`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const { useContactUsMutation } = contactUsAPI;
