import { clientAPI } from "../../api/clientAPI/clientAPI";
import { contactUsAPI } from "../../api/contactUs/contactUsAPI";
import { eventAPI } from "../../api/eventAPI/eventAPI";
import { eventTypeAPI } from "../../api/eventTypeAPI/eventTypeAPI";
import { gallaryAPI } from "../../api/gallaryAPI/gallaryAPI";
import { partinerAPI } from "../../api/partinerAPI/partinerAPI";

// => RTK middleware
export const RTKmiddleware = [
  clientAPI.middleware,
  eventTypeAPI.middleware,
  partinerAPI.middleware,
  gallaryAPI.middleware,
  eventAPI.middleware,
  contactUsAPI.middleware,
];
