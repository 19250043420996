import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import CustomTextField from "../forms/CustomTextField";
import CustomMultlineTextField from "../forms/CustomMultlineTextField";
import CustomSubmitButton from "../forms/CustomSubmitButton";
import { Send } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import contactUsBg from "../../assets/media/images/logo2.png";
import { useContactUsMutation } from "../../api/contactUs/contactUsAPI";
import * as Yup from "yup";
import { errorNotify, successNotify } from "../../helpers/notify";

// ############# contactUsValidationSchema ###################
const contactUsValidationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
});

const ContactUsWidget = () => {
  // ############## RTK ##################
  const [sendMessage, { isLoading, isSuccess, isError }] =
    useContactUsMutation();

  // => Response
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (isSuccess) {
        successNotify("Email sent successfully");
      }
      if (isError) {
        errorNotify("Fail to send email. Please try again!");
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [isSuccess, isError]);

  return (
    <>
      <Box
        sx={{
          mt: -25,
          backgroundImage: `url(${contactUsBg})`,
          backgroundPosition: "center",
          backgroundSize: "100% 400px",
          backgroundRepeat: "no-repeat",
          borderTopLeftRadius: {
            md: 100,
            xs: 50,
          },
          borderBottomRightRadius: {
            md: 100,
            xs: 50,
          },
          position: "relative",
          overflow: "hidden",
          height: { md: 500, xs: 450 },
          border: `1px dotted ${colors.secondary}`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: `rgb(236, 246, 255, .95)`,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  md: 25,
                  xs: 20,
                },
                fontWeight: "bold",
                pt: 4,
                pb: 2,
                textAlign: "center",
                color: colors.textColor,
                opacity: 0.8,
                textTransform: "uppercase",
              }}
            >
              Leave us{" "}
              <span
                style={{
                  color: colors.secondary,
                }}
              >
                a message
              </span>
            </Typography>
          </Box>
          {/* ############## Leave us Message ################## */}
          <Box>
            <Formik
              initialValues={{
                name: "",
                email: "",
                subject: "",
                message: "",
              }}
              validationSchema={contactUsValidationSchema}
              onSubmit={(payload, { resetForm }) => {
                sendMessage(payload);
                // if (isSuccess) {
                resetForm();
                // }
              }}
            >
              {(formik) => (
                <Form>
                  <Grid container justifyContent={"center"} columnSpacing={2}>
                    {isSuccess && (
                      <Grid item sm={10} xs={11}>
                        <Box className="success-message">
                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Email sent successfully
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item sm={5} xs={5.5}>
                      <CustomTextField
                        name="name"
                        type={"text"}
                        label={"Full name"}
                      />
                    </Grid>
                    <Grid item sm={5} xs={5.5}>
                      <CustomTextField
                        name="email"
                        type={"email"}
                        label={"Your email"}
                      />
                    </Grid>
                    <Grid item sm={10} xs={11}>
                      <CustomTextField
                        name="subject"
                        type={"text"}
                        label={"Subject"}
                      />
                    </Grid>
                    <Grid item sm={10} xs={11}>
                      <CustomMultlineTextField
                        name="message"
                        type={"text"}
                        label={"Message"}
                        rows={4}
                      />
                    </Grid>

                    <Grid item sm={10} xs={11} sx={{ mt: 2 }}>
                      <CustomSubmitButton
                        endIcon={<Send />}
                        loading={isLoading}
                        onClick={formik.handleSubmit}
                      >
                        Send message
                      </CustomSubmitButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUsWidget;
